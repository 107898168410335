import gql from 'graphql-tag';

const horariosTableQuery = gql`
    query horarios($whereConditions:HorariosWhereWhereConditions,$numberItems: Int!, $numberPage: Int!, $fieldOrder: String!, $Order: SortOrder!) {
        horarios(where:$whereConditions,first: $numberItems, page: $numberPage, activo: true, orderBy:[{field: $fieldOrder, order: $Order}]) {
            data {
                id,
                nombre, 
                cliente_id,
                empresa_id,
                sucursal_id,
                descripcion,
                detallesHorarios{dia,hora_entrada,hora_salida}
                empresa{nombre}
                sucursal{nombre}
            }
            paginatorInfo {
                count
                currentPage
                firstItem
                lastItem
                hasMorePages
                lastPage
                perPage
                total
            }
        }
    }
`

const queries = { horariosTableQuery };

export default queries;
